import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import IniciarSesionWith from "./auth/IniciarSesionWith";
import Login from "./auth/Login";
import RestablecerPassword from "./auth/RestablecerPassword";
import ConfirmarCorreoRecuperar from "./auth/ConfirmarCorreoRecuperar";
import CambiarPassword from "./auth/CambiarPassword";
import CambiadoPassword from "./auth/CambiadoPassword";
import CrearCuenta from "./auth/CrearCuenta";
import ConfirmarRegistro from "./auth/ConfirmarRegistro";
import RegistroExitoso from "./auth/RegistroExitoso";
import { useTranslation } from "../hooks/useTranslation";


function Box(section) {

  const { translate } = useTranslation();
  
  const renderTitle = (title) => {
    return (
      <div>
        <h5>{title}</h5>
      </div>
    );
  };
  const titles = {
    IniciarSesionWith: renderTitle(translate('login.title')),
    Login: renderTitle(translate('login.title')),
    RestablecerPassword: renderTitle(translate('recovery.title')),
    ConfirmarCorreoRecuperar: renderTitle(translate('recovery.title')),
    CambiarPassword: renderTitle(translate('recovery.change')),
    CrearCuenta: renderTitle(translate('login.create')),
    ConfirmarRegistro: renderTitle(translate('login.confirm_email')),
  };
  const pages = {
    IniciarSesionWith: <IniciarSesionWith />,
    Login: <Login />,
    RestablecerPassword: <RestablecerPassword />,
    ConfirmarCorreoRecuperar: <ConfirmarCorreoRecuperar />,
    CambiarPassword: <CambiarPassword />,
    CambiadoPassword: <CambiadoPassword />,
    CrearCuenta: <CrearCuenta />,
    ConfirmarRegistro: <ConfirmarRegistro />,
    RegistroExitoso: <RegistroExitoso />,
  };
  const footer = (section) => {
    /**
     *
     * ! className='TextColorSec'
     * ? show lines inside as linear gris
     *
     */
    if (
      section == "ConfirmarCorreoRecuperar" ||
      section == "CambiarPassword" ||
      section == "CambiadoPassword" ||
      section == "CrearCuenta" ||
      section == "ConfirmarRegistro"
    ) {
      return null;
    }
    return (
      <div className={section == "RestablecerPassword" ? "TextColorSec" : ""}>
        <p>{translate('login.no_register')} </p>
        <Link to="/login-crear">
          <b>{translate('login.create')}</b>
        </Link>
      </div>
    );
  };
  return (
    <>
      {section == "CambiadoPassword" ? null : titles[section]}
      {pages[section]}
      {footer(section)}
    </>
  );
}
function ReturnAuth(page) {
  const navigate = useNavigate();
  return page == "RegistroExitoso" ? (
    <RegistroExitoso />
  ) : (
    <main className="ContentBox ContentChildCenter FontColorLight TextCenter">
      <div className="IniciarSesion">
        <div
          className="ImageIcon ArtOnU"
          onClick={() => navigate("/home")}
        ></div>
        <div
          className="ImageIcon ArtFans"
          onClick={() => navigate("/login")}
        ></div>
        {
          //
          /**
           *
           * * show pages -> put keys of {pages} const
           * @IniciarSesionWith
           * @Login
           * @RestablecerPassword
           * @ConfirmarCorreoRecuperar
           * ? RegistroExitoso
           * ? CambiarPassword
           * @CrearCuenta
           * @ConfirmarRegistro
           *
           */
        }
        {Box(page)}
      </div>
    </main>
  );
}
const Component = ({ page }) => {
  const location = useLocation().pathname;
  return ReturnAuth(page);
};

export default Component;

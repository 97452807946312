import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../context/CardContext";
import Spinner from "../../components/Spinner";
import { useTranslation } from "../../hooks/useTranslation";

const PreviewItemCarrito = ({ producto,redirect=true }) => {
  const {translate} = useTranslation();
  const navigate = useNavigate();
  const { removeToCart } = useContext(CartContext);
  const [loading,setLoading] = useState(true)

  const remove= ()=>{
    setLoading(false)
     
    setLoading(removeToCart(producto))

    if(redirect)
      navigate("/detalles-producto/" + producto.uri)
  }

  return (
    <div className="AgregadoItem">
      {!loading && <Spinner />}

      <div>
        <div
          className="AgregadoItemImg"
          style={{ backgroundImage: `url(${producto.desktop_image})` }}
        ></div>
        <div>
          <p>{producto.name}</p>
          <em>
            <b>{translate('product.size')}: </b>{producto.size}
          </em>

          <em>{producto.description}</em>
        </div>
      </div>
      <div>
       {producto.quantity} x ${producto.price}

        <div className="ButtonDarkThiny icon-Trash" onClick={() => {remove()}}>{translate('product.delete')}</div>
        <div
          className="ButtonLightThiny icon-Edit"
          onClick={() => navigate("/detalles-producto/" + producto.uri)}
        >
          {translate('product.edit')}
        </div>

      </div>
    </div>
  );
};

export default PreviewItemCarrito;

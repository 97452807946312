import React from 'react';
import { useNavigate } from 'react-router-dom';
const Direccion = ({ stored, address,edit }) => {

	const navigate = useNavigate();
	return (
		<label className='ItemDireccion'>
			{!stored && (
				<input type='radio' name='direccionenvio'></input>
			)}
			<div className={stored ? 'Selected' : ''}>
			<div>{address.line}</div>
			<div>{address.line2}</div>
			<div>{address.country_name} {address.city}, {address.postal_code} </div>
				<div style={{ cursor: 'pointer' }} className='EditarDireccion' onClick={() => { navigate(`/mi-perfil/editar-direccion/${address.id}`) }}>{edit}</div>
			</div>
		</label>
	)
};

export default Direccion;
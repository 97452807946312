import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";

const Component = ({ ped }) => {

  const navigate = useNavigate();
  const {translate} = useTranslation();

  return (
    <div className="AgregadoItem">
      <div>
        <div
          className="AgregadoItemImg"
          style={{ backgroundImage: `url(${ped.desktop_image})` }}
        ></div>
        <div>
          <p>{ped.name}</p>
          <em>{translate('product.quantity')}: {ped.quantity} </em>
          <em> {translate('product.size')}: {ped.size} </em>
          {/* <em>
              <b>Fecha de la compra: </b>
              <span>02 Noviembre 2023</span>
            </em> */}

          <em>{ped.description}</em>
          <div className="links">
            <a onClick={() => navigate("/detalles-producto/" + ped.uri)}
              style={{ cursor: "pointer" }}
            >Comprar nuevamente</a>
            <a onClick={() => { navigate("/detalles-producto/" + ped.uri) }} style={{ cursor: "pointer" }}>Ver articulo</a>
          </div>
        </div>
      </div>
      <div>
        ${ped.subtotal}

      </div>
    </div>
  );
};

export default Component;

import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import axios from "../../fetch/axios"
import { useTranslation } from '../../hooks/useTranslation';

const ProductoAgotadoBox = ({ product,isModalOpen, setIsModalOpen }) => {
    const [email, setEmail] = useState(null)
    const productId = product.id
    const { enqueueSnackbar } = useSnackbar();
    const {translate}  = useTranslation();

    const enviar = async () => {
        if (!email) {
            enqueueSnackbar("Faltan campos por llenar", { variant: "error" });
            return;
        }

        try {
            const res = await axios.post(
                "/stock-alerts/create",
                {
                    "email": email,
                    "product_id": productId,
                }
            );
            if (res.data.errors) {
                enqueueSnackbar(res.data.message, { variant: "error" });
            } else {
                setIsModalOpen(false);

                setEmail('')
                enqueueSnackbar("Mensaje reenviado con éxito", { variant: "success" });
            }
        } catch (error) {
            enqueueSnackbar(error.response.data, { variant: "error" });
        }
    }

    return (
        isModalOpen && (
            <div className='ProductoAgotado'>
                <div>
                    <i className='icon-Closed' onClick={() => setIsModalOpen(false)}></i>
                    <h4>{translate('product.no_stock')}.</h4>
                    <p>
                        {translate('product.no_stock_layer')}
                    </p>
                    <div className='FormInput'>
                        <input placeholder={translate('view.myprofile.email')} onChange={(e) => { setEmail(e.target.value) }} value={email} />
                    </div>
                    <div
                        onClick={() => { enviar() }}
                        className='ButtonDarkMedium'
                    >
                        <i className='icon-Bell' ></i>
                        {translate('product.store')}
                    </div>
                </div>
            </div>
        )
    );
};

export default ProductoAgotadoBox;

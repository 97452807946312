import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import PreviewItemCarrito from "../home/PreviewItemCarrito";
import axios from "../../fetch/axios"
import { TokenContext } from "../../context/TokenContext";
import { useTranslation } from "../../hooks/useTranslation";

const Component = () => {

  const navigate = useNavigate();
  const [productos, setProductos] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [total, setTotal] = useState(null);
  const { getToken } = useContext(TokenContext);
  const {translate} = useTranslation();

  useEffect(() => {

    const fetchCarrito = async () => {

      if (localStorage.getItem('cartId') !== null) {

        try {

          const res = await axios.get('/cart/order?id=' + localStorage.getItem('cartId'),
            {
              headers: { 'Authorization': 'Bearer ' + getToken() }
            }
          );
          if (res.data) {
            setProductos(res.data.cart_products)
            setDiscount(res.data.discount)
            setShipping(res.data.shipping)
            setSubtotal(res.data.subtotal)
            setTotal(res.data.total)
          }


        } catch (error) {
        }

      }

    };

    fetchCarrito()

  }, [])

  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected" onClick={() => navigate('/checkout-envio')} style={{ cursor: "pointer" }}></div>
          <div className="Selected"></div>
          <div></div>
          <div></div>
        </div>
        <div className="icon-Package"></div>
        <h5>{translate('summary.title')}</h5>
        <div className="ResumenPedido">
          <div>
            {productos.length > 0 && productos.map((producto, index) => (
              <PreviewItemCarrito key={index} producto={producto} redirect={false} />
            ))}

          </div>
          <div className="ResumenCheckout">
            <div>
              <span>Subtotal</span>
              <span>${subtotal}</span>
            </div>
            {/* <div>
              <span>Impuestos</span>
              <span>$00.00</span>
            </div> */}
            {discount &&
              <div>
                <span>{translate('shopping.discount')}</span>
                <span>${discount}</span>
              </div>
            }
            <div>
              <span>{translate('shopping.shipping')}</span>
              <span>${shipping}</span>
            </div>
            <div>
              <h5>Total:</h5>
              <h5>${total}</h5>
            </div>
          </div>
        </div>
        <div
          className="ButtonDarkMedium"
          onClick={() => navigate("/checkout-pago")}
        >
          {translate('checkout.continue')}
          <i className="icon-Check"></i>
        </div>
      </article>
    </section>
  );
};

export default Component;

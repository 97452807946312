import { useContext } from 'react';
import { LanguageCurrencyContext } from '../context/LanguageCurrencyContext';
import { translationsES } from '../translations/es.js';
import { translationsEN } from '../translations/en.js';

export const useTranslation = () => {
  const { lang } = useContext(LanguageCurrencyContext);
  
  const getTranslation = (key) => {
    const [section, ...rest] = key.split('.');
    const currentLang = lang === 'es' ? translationsES : translationsEN;
    
    return rest.reduce((obj, k) => obj?.[k], currentLang[section]);
  };

  return { translate: getTranslation };
};
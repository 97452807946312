import React, { useContext, useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "../../fetch/axios";
import { CartContext } from "../../context/CardContext";
import { TokenContext } from "../../context/TokenContext";
import { useNavigate } from "react-router-dom";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
    return <p>{content}</p>;
}

function PayPaypal() {
    const navigate = useNavigate();
    const { getCardId, resetCart } = useContext(CartContext);
    const { getToken } = useContext(TokenContext);
    const [clientId, setClientId] = useState(null)

    // const initialOptions = {
    //     "client-id": "AeGF8C1OcfMk0WTaoMK_O7wljKkQcNZn5Or89vw9Amz94PnLq2A7kwcChE51PlsFoyylPgWuUTF7JLE6",
    //     currency: localStorage.getItem('currency_arteonu') !== null ? (localStorage.getItem('currency_arteonu') == 'mxn' ? 'MXN' : 'USD') : 'MXN',
    // };

    const [message, setMessage] = useState("");

    useEffect(() => {


        const fetchKey = async () => {
            try {

                const storedToken = getToken();

                const response = await axios.get('/cart/paypal/client-id',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${storedToken}`
                        },
                    }
                );

                setClientId(response.data.client_id)

            } catch (error) {
                console.error('Error fetching address list', error);
            }
        }
        fetchKey()
    }, [])
    return (
        <div className="FormButtonPaypal">
            {clientId &&
                <>
                    <PayPalScriptProvider options={{
                        "client-id": clientId,
                        currency: localStorage.getItem('currency_arteonu') !== null ? (localStorage.getItem('currency_arteonu') == 'mxn' ? 'MXN' : 'USD') : 'MXN',    
                        "enable-funding": "venmo",
                        "disable-funding": "card",
                    }}>
                        <PayPalButtons
                            style={{
                                shape: "rect",
                                layout: "vertical",
                                color: "gold",
                                label: "paypal",
                            }}
                            createOrder={async () => {
                                try {
                                    const res = await axios.post('/cart/paypal/create-order',
                                        {
                                            "cart_id": getCardId()
                                        },
                                        {
                                            headers: { 'Authorization': 'Bearer ' + getToken() }
                                        });


                                    if (res.data) {
                                        return res.data.id;
                                    } else {
                                        const errorDetail = res.data?.details?.[0];
                                        const errorMessage = errorDetail
                                            ? `${errorDetail.issue} ${errorDetail.description} (${res.data.debug_id})`
                                            : JSON.stringify(res.data);

                                        throw new Error(errorMessage);
                                    }
                                } catch (error) {
                                    console.error(error);
                                    setMessage(
                                        `Could not initiate PayPal Checkout...${error}`
                                    );
                                }
                            }}
                            onApprove={async (data, actions) => {

                                try {

                                    const res = await axios.post('/cart/paypal/capture-order',
                                        {
                                            "cart_id": localStorage.getItem('cartId'),
                                            "order_id": data.orderID
                                        },
                                        {
                                            headers: { 'Authorization': 'Bearer ' + getToken() }
                                        }

                                    );

                                    const orderData = res.data;
                                    // Three cases to handle:
                                    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                    //   (2) Other non-recoverable errors -> Show a failure message
                                    //   (3) Successful transaction -> Show confirmation or thank you message

                                    const errorDetail = orderData?.details?.[0];

                                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                        return actions.restart();
                                    } else if (errorDetail) {
                                        // (2) Other non-recoverable errors -> Show a failure message
                                        throw new Error(
                                            `${errorDetail.description} (${orderData.debug_id})`
                                        );
                                    } else {
                                        // (3) Successful transaction -> Show confirmation or thank you message
                                        // Or go to another URL:  actions.redirect('thank_you.html');
                                        const transaction =
                                            orderData.purchase_units[0].payments
                                                .captures[0];
                                        setMessage(
                                            `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                                        );
                                        console.log(
                                            "Capture result",
                                            orderData,
                                            JSON.stringify(orderData, null, 2)
                                        );
                                        localStorage.setItem('cart', '[]')
                                        localStorage.removeItem('cartId')
                                        localStorage.removeItem('paypal_arteonu')
                                        resetCart()
                                        navigate('/checkout-exito')
                                    }



                                } catch (error) {
                                    console.error(error);
                                    setMessage(
                                        `Sorry, your transaction could not be processed...${error}`
                                    );
                                }

                            }}
                        />
                    </PayPalScriptProvider>
                    <Message content={message} />
                </>
            }
        </div>
    );
}

export default PayPaypal

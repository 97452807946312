export const translationsEN = {
  welcome: "Welcome to ArteOnU!",
  my_account_: "My Account",
  interesed: 'You may also be interested in',
  view: {
    shop: "VIEW SHOP",
    profile: "VIEW PROFILE",
    myprofile:{ 
      my_profile:"My Profile",
      my_info:"My Info",
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      membership_number:'Membership Number',
      edit: 'Edit',
      edit_my_profile: 'Edit my profile',
      log_out: 'Log Out',
      store:'SAVE DATA',
    },
    orders:{
      title:'My orders',
      subtitle:'Check packages, returns',
      view:'VIEW MY ORDERS',
    },
    address:{
      title:'Your Addresses',
      subtitle:'Saved Addresses',
      edit:'Edit',
      add:'Add',
      address:'Address',
      edit_shipping:'Edit shipping address',
      add_shipping:'Add shipping address',
      street_numer_apt_1:'Line 1',
      street_numer_apt_2:'Line 2',      
      postal_code:'Postal Code',
      city:'City',
      detail:'Details',
      adddetail:'Add address details',
      update:'UPDATE DATA',
      store:'SAVE DATA',
      delete:'Remove this shipping address',
    },
    loading:'loading...',
  }, 
  login: {
    title: 'Log In',
    titleM: 'LOG IN',
    registerM: 'REGISTRARSE',
    already_account:'Already have an account?',
    country: 'Country',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    submit: 'LOGIN',
    forgotPassword: 'Forgot your password?',
    recoverPassword: 'Recover Password',
    google: 'Continue with Google',
    facebook: 'Continue with Facebook',
    wemail: 'Continue with Email',
    no_register: 'Not registered yet?',
    create: 'Create an account',
    password_no: 'Incorrect password',
    forgout: 'Forgot your password?',
    confirm_email: 'Confirm your email address',
    register_success: 'Registration successful',
    phone:'Cell phone number',
    term1:'By clicking on “Register” you accept our terms and conditions',
    term2:'Terms and Conditions',
    term3:'Terms of Use',
    term4:'Terms of Service',
    term5:'Privacy Policy',
    term6:'as well as the',
    term7:'Privacy Notice',
  },
  recovery: {
    enter: 'Enter the email address you used when you registered to retrieve your password. You will receive a link to reset your password',
    title: 'Recover password',
    titleM: 'RECOVER PASSWORD',
    change: 'Change password',
  },
  shopping:{
    title:'Shopping cart',
    discount_code:'Discount code',
    if_code:'If you have a discount code, enter it here.',
    total_products:'Total products',
    subtotal:'Subtotal',
    discount:'Discount',
    shipping:'Shipping',
    purchased:'MAKE A PURCHASE',
    apply:'APPLY',
  },
  product:{
    detail:'Product details',
    size:'Size',
    store:'STORE',
    delete:'Delete',
    edit:'Edit',
    quanty:'Quanty',
    find_size:'Find your size',
    select_size:'Select size',
    important:'Important',
    size_information:'Size measurements may vary on the product. Please check  “find your size” section and choose the one that will fits you better.',
    no_available:'Product NO AVAILABLE',
    add_cart:'ADD TO CART',
    buy:'BUY NOW',
    leet_me:'LET ME KNOW WHEN IT IS AVAILABLE',
    will_send:'We will send you an email when this product is available.',
    no_stock:'Product out of stock',
    no_stock_layer:'This product is out of stock. You can enter your email and we will send you an email when the product is available again',
  },
  checkout:{
    address:'Shipping address',
    stored:'Saved addresses',
    select:'Select where you want to send your order.',
    with_pay:'We offer standard shipping by default, if you want another type of shipping, please contact us.',
    add_address:'ADD ANOTHER ADDRESS',
    edit_address:'Edit',
    continue:'Continue',
    indicated:'Indicate the address where we will send your purchase.',
    information:'Payment information',
    credicard:'Credit or Debit Card',
    card:'Card',
    paypal:'Your payment will be made from your PayPal account',
    success:'Thanks for your support!',
    your_pay:'Your payment was successful',
    send1:'We have sent an email to ',
    send2:'with the details of your purchase',
    check:'CHECK MY ORDERS',
    continue_shopping:'CONTINUE SHOPPING'
  },
  summary:{
    title:'Your Order',
    see:'View summary'
  },
  legal:{
    dudas:"Do you have doubts?",
    contact:"CONTACT US",
    description:"Get in touch with us!"
  }
};
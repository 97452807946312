import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemDireccion from "../../components/ItemDireccion";
import { TokenContext } from "../../context/TokenContext";
import axios from "../../fetch/axios";
import { useSnackbar } from "notistack";
import { CartContext } from "../../context/CardContext";
import { useTranslation } from "../../hooks/useTranslation";

const DireccionEnvio = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { getToken } = useContext(TokenContext);
  const { getCardId } = useContext(CartContext);
  const [direcciones, setDirecciones] = useState([]);
  const [ocultar, setOcultar] = useState(true)
  const [selectedDireccionId, setSelectedDireccionId] = useState(null);

  const [street1, setStreet1] = useState(null);
  const [street2, setStreet2] = useState(null);
  const [cp, setCp] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(142);
  const [detail, setDetail] = useState(null);
  const {translate} =useTranslation();

  const handleSelect = (direccionId) => {
    setSelectedDireccionId(direccionId);
    setOcultar(true)
  };

  const handleEditar = (dir) => {
    setSelectedDireccionId(dir.id);
    setStreet1(dir.line)
    setStreet2(dir.line2)
    setCp(dir.postal_code)
    setDetail(dir.details)
    setCity(dir.city)
    setOcultar(false)
  };

  const handlerOcultar = () => {
    if (ocultar) {
      setSelectedDireccionId(false)
      setStreet1('')
      setStreet2('')
      setCp('')
      setDetail('')
      setCity('')
    }
    setOcultar(!ocultar)
  }

  const guardar = async (type = 1) => {

    if (!street1) {
      enqueueSnackbar("El campo de dirección es obligatorio", { variant: "error" });
      return;
    }
    if (!cp || isNaN(cp)) {
      enqueueSnackbar("El código postal debe ser un número entero", { variant: "error" });
      return;
    }
    if (!city) {
      enqueueSnackbar("El campo de ciudad es obligatorio", { variant: "error" });
      return;
    }
    try {
      const dir= await axios[type == 1 ? 'post' : 'put'](
        type == 1 ? '/addresses/create' : '/addresses/update',
        {
          id: selectedDireccionId ? selectedDireccionId : null,
          country_id: country,
          line: street1,
          line2: street2,
          postal_code: parseInt(cp),
          city: city,
          details: detail
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        }
      );
      enqueueSnackbar("Dirección guardada con éxito", { variant: "success" });
      setOcultar(true)
      setSelectedDireccionId(dir.data.id)
      setStreet1('')
      setStreet2('')
      setCp('')
      setDetail('')
      setCity('')

      fetchDirecciones()
    } catch (error) {
      console.error('Error al guardar la dirección:', error);
    }


  };

  const fetchDirecciones = async () => {
    try {

      const storedToken = getToken();

      const response = await axios.get('/addresses/list',
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`
          },
        }
      );
      setDirecciones(response.data);
    } catch (error) {
      console.error('Error fetching address list', error);
    }
  };

  const continuar = async()=>{

    if(!selectedDireccionId){
      enqueueSnackbar("Debe seleccionar una dirección", { variant: "error" });
      return
    }else{

      try {

        const storedToken = getToken();
  
        const response = await axios.put('/cart/address',
          {
            
              "cart_id": getCardId(),
              "address_id": selectedDireccionId
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${storedToken}`
            },
          }
        );
        navigate("/checkout-resumen")
      } catch (error) {
        console.error('Error fetching address list', error);
      }  
    }
  }

  useEffect(() => {


    fetchDirecciones()

  }, [])

  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="icon-HouseLine" onClick={()=>navigate('/home')}></div>
        <h5>{translate('checkout.address')}</h5>
        <div className="DireccionesGuardadas">
          <div>{translate('checkout.stored')}</div>
          <div className="FontSmall">
            {translate('checkout.select')}
          </div>
          {direcciones.length > 0 &&

            direcciones.map((direccion, index) => (
              <ItemDireccion key={index} direccion={direccion} onSelect={handleSelect} handleEditar={handleEditar} />
            ))}

          <div className="text-description">{translate('checkout.with_pay')} </div>

          {ocultar &&
            <div className="ButtonLightMedium" onClick={() => handlerOcultar()}>
              <i>+</i>
              {translate('checkout.add_address')}
            </div>}
        </div>

        

        {!ocultar &&

          <div className="FormDireccion">
            <div>{translate('view.address.add_shipping')}</div>
            <div className="FontSmall">
              {translate('checkout.indicated')}
            </div>
            <label className="FormData">
              <em className="RequiredData">{translate('view.address.street_numer_apt_1')}</em>
              <div className="FormInputLight">
                <input placeholder={translate('view.address.street_numer_apt_1')} onChange={(e) => { setStreet1(e.target.value) }} value={street1}/>
              </div>
            </label>
            <label className="FormData">
              <em className="RequiredData">{translate('view.address.street_numer_apt_2')}</em>
              <div className="FormInputLight">
                <input placeholder={translate('view.address.street_numer_apt_2')} onChange={(e) => { setStreet2(e.target.value) }} value={street2}/>
              </div>
            </label>
            <div className="Row">
              <label className="FormData">
                <em className="RequiredData">{translate('view.address.postal_code')}</em>
                <div className="FormInputLight">
                  <input placeholder={translate('view.address.postal_code')} onChange={(e) => { setCp(e.target.value) }}  value={cp} />
                </div>
              </label>
              <label className="FormData">
                <em className="RequiredData">{translate('view.address.city')}</em>
                <div className="FormInputLight">
                  <input placeholder={translate('view.address.city')} onChange={(e) => { setCity(e.target.value) }} value={city}  />
                </div>
              </label>
            </div>
            <label className="FormData">
              <em className="RequiredData">{translate('login.country')}</em>
              <div className="FormInputLight">
                <select placeholder="Email" onChange={(e) => { setCountry(e.target.value) }} value={country}>
                  <option value="124" selected>
                    México
                  </option>
                  <option value="233">USA</option>
                </select>
                <i className="InputIcon icon-Select"></i>
              </div>
            </label>
            <label className="FormData">
              <em>{translate('view.address.detail')}</em>
              <div className="FormInputLight">
                <textarea placeholder={translate('view.address.add_shipping')} onChange={(e) => { setDetail(e.target.value) }} value={detail}></textarea>
              </div>
            </label>
            <button className="ButtonLightMedium" onClick={() => { guardar(selectedDireccionId ? 2 : 1) }}>
              {selectedDireccionId ? translate('view.address.update') : translate('view.address.store')}
            </button>
          </div>

        }
        <div
          className="ButtonDarkMedium" style={{ marginTop:"13px" }}
          onClick={() => continuar()}
        >
          {translate('checkout.continue')}
          <i className="icon-Check"></i>
        </div>
      </article>
    </section>
  );
};
export default DireccionEnvio;

// src/components/Footer.js
import React from "react";
import { useNavigate, Link } from "react-router-dom";

const Component = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="FooterFirst">
        <div className="ImageIcon ArtOnU" onClick={() => navigate("/")}></div>
        <div className="FooterLinks">
          <div className="TextLeft">
            <div>
              <h5>Información</h5>
              <Link to="/terminos-y-condiciones">Términos y Condiciones</Link>
              <Link to="/terminos-de-servicios">Términos de Servicio</Link>
              <Link to="/terminos-de-privacidad">Términos de Privacidad</Link>
            </div>
            <div>
              <Link to="/cambios-y-devoluciones">Cambios y Devoluciones</Link>
              <Link to="/tabla-de-tallas">Tabla de Tallas</Link>
              <Link to="/envios">Envíos</Link>
              <Link to="/cookies">Cookies</Link>
            </div>
          </div>
        </div>
        <div className="FooterSocial">
          <a href="/" target="_blank" className="icon-Facebook"></a>
          <a href="/" target="_blank" className="icon-Instagram"></a>
          <a href="/" target="_blank" className="icon-Tiktok"></a>
          <a href="/" target="_blank" className="icon-X"></a>
        </div>
      </div>
      <div>© ArteOnU - Todos los derechos reservados 2024</div>
    </footer>
  );
};

export default Component;

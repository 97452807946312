import React, { useState, useRef, useEffect } from "react";
import Figure from "../../components/Figure";
import axios from "../../fetch/axios"
import { useTranslation } from "../../hooks/useTranslation";

const PodriaInteresarte = ({ title,  }) => {

  const [dragStartX, setDragStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const contentRef = useRef(null);
  const [related,setRelated] = useState([])
  const {translate} = useTranslation();

  useEffect(() => {
    const handleMove = (clientX) => {
      if (dragStartX !== 0) {
        const dx = clientX - dragStartX;
        setScrollLeft((prevMargin) => {
          const totalWidth = contentRef.current.scrollWidth;
          const containerWidth = contentRef.current.clientWidth;
          const newMargin = prevMargin - dx;
          return Math.min(0, Math.max(containerWidth - totalWidth, newMargin));
        });
        setDragStartX(clientX);
      }
    };
    const handleMouseMove = (e) => handleMove(e.clientX);
    const handleTouchMove = (e) => handleMove(e.touches[0].clientX);

    const handleEnd = () => setDragStartX(0);

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleEnd);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleEnd);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleEnd);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleEnd);
    };
  }, [dragStartX]);


  const handleStart = (clientX) => setDragStartX(clientX);
  const handleMouseDown = (e) => {
    handleStart(e.clientX);
  };
  const handleTouchStart = (e) => handleStart(e.touches[0].clientX);

  useEffect(()=>{

		const fetchPosts = async () => {
			try {

        let url = '/products/others';
        if(localStorage.getItem('cartId')!==null){
          url=url+'?cart_id='+localStorage.getItem('cartId')
        }
			  const response = await axios.get(url);
			  setRelated(response.data);
			} catch (error) {
			  console.error('Error fetching Instagram posts', error);
			} 
		};

		fetchPosts();
  },[]);

  return (
    <section
      className="InteresBox"
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      <b>{title ? title : translate('interesed')}</b>
      <div
        className="InteresBoxImgs"
        ref={contentRef}
        style={{
          left: `${scrollLeft}px`,
        }}
      >
        {related.map((product, index) => (
          <Figure 
            key={product.id} 
            image={product.desktop_image}
            name={product.name}
            price={product.price}
            uri={product.uri}
          />
        ))}
      </div>
    </section>
  );
};

export default PodriaInteresarte;

import React from 'react';
import { useTranslation } from '../hooks/useTranslation';

const ItemDireccion = ({ direccion, onSelect,handleEditar }) => {

  const {translate} = useTranslation();

  return (
    <label className='ItemDireccion'>
      <input 
        type='radio' 
        name='direccionenvio'
        onChange={() => onSelect(direccion.id)}
      />
      <div className={direccion.selected ? 'Selected' : ''}>
        <div>{direccion.line},{direccion.line2 ? direccion.line2 + ', ': ''} {direccion.country_name} {direccion.city}, {direccion.postal_code}</div>
        <div>{direccion.details}</div>
        <div className='EditarDireccion' style={{ cursor:"pointer" }}
		onClick={() => handleEditar(direccion)}
		>{translate('checkout.edit_address')}</div>
      </div>
    </label>
  );
};

export default ItemDireccion;

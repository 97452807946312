import React from "react";
import { useTranslation } from "../hooks/useTranslation";
const Component = ({ title, perfil, databc = [] }) => {

  const {translate} = useTranslation();

  return (
    <div className={`CategoryTitle${perfil ? " PerfilSection" : ""}`}>
      <ul className="Breadcrumb">
      <li>Home</li>
      {perfil ? <li>{translate('view.myprofile.my_profile')}</li> : <li>{title}</li>}
        {databc.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      {title && <h3>{title}</h3>}
    </div>
  );
};

export default Component;

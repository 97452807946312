import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/icons/cards.svg";
import logopaypal from "../../assets/icons/paypal_logo.svg";
import formLoremPaypal from "../../assets/images/FormLoremPaypal.png";
import { CartContext } from "../../context/CardContext";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";
import { useSnackbar } from "notistack";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import "../../styles/CheckStripe.scss"
import PayPaypal from "./PayPaypal";
import { useTranslation } from "../../hooks/useTranslation";

const Component = () => {

  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState("tarjeta"); // Estado para controlar la selección
  const { getCardId,totalPay } = useContext(CartContext);
  const { getToken } = useContext(TokenContext);
  const { enqueueSnackbar } = useSnackbar();
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setPublicKey] = useState("");
  const [paypalLink, setPaypalLink] = useState("");
  const {translate} = useTranslation();

  const appearance = {
    theme: 'stripe',
  };
  const loader = 'auto';

  useEffect(()=>{

    const fetchStripeKey = async () => {

      try {

        if(getCardId()){
          const res = await axios.get('/cart/stripe/payment?cart_id='+getCardId(),{
            headers: {'Authorization': 'Bearer ' + getToken()}
          });
          if(res.data){

            setClientSecret(res.data.client_secret)
            setPublicKey(loadStripe(res.data.public_key))
            
          }else{
            enqueueSnackbar("Checkout no disponible, intentelo de nuevo", { variant: "error" });
          }
          
        }

      } catch (error) {
        enqueueSnackbar("Stripe no disponible", { variant: "error" });
        // navigate('/carrito')
      }
    };

    // const fetchPaypalLink = async () => {

    //   try {

    //     if(getCardId()){
    //       const res = await axios.post('/cart/paypal/create-order',
    //       {
    //         "cart_id":getCardId()
    //       },
    //       {
    //         headers: {'Authorization': 'Bearer ' + getToken()}
    //       });
    //       if(res.data){

    //         setPaypalLink(res.data.links[1].href)
            
    //       }else{
    //         // enqueueSnackbar("Checkout no disponible, intentelo de nuevo", { variant: "error" });
    //       }
          
    //     }
    //   } catch (error) {
    //     enqueueSnackbar("Paypal no disponible", { variant: "error" });
    //     // navigate('/mi-perfil')
    //   }
    // };

    // fetchPaypalLink()
    fetchStripeKey()

  },[]);

  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected" onClick={()=>navigate('/checkout-envio')} style={{ cursor:"pointer" }}></div>
          <div className="Selected" onClick={()=>navigate('/checkout-resumen')} style={{ cursor:"pointer" }}></div>
          <div className="Selected"></div>
          <div></div>
        </div>
        <div className="icon-Wallet"></div>
        <div className="line">
          <h5>{translate('checkout.information')}</h5>
          <div className="box-price">
            <span>Total:</span>
            <div className="price">${totalPay}</div>
            <a href="#" onClick={(e) => { e.preventDefault(); navigate('/checkout-resumen'); }}
              >
              {translate('summary.see')}
              </a>
          </div>
        </div>

        {/* Opción Tarjeta */}
        <div className="TarjetasGuardadas">
          <div>{translate('checkout.credicard')}</div>
          <label className="ItemTarjeta">
            <input
              type="radio"
              name="payment"
              checked={selectedPayment === "tarjeta"}
              onChange={() => setSelectedPayment("tarjeta")}
            />
            <div className="Selected">
              <div>
                <img src={logo} alt="logo tarjeta" />
              </div>
            </div>
          </label>
        </div>
        {selectedPayment === "tarjeta" && (
          <div className="FormTarjeta">
            {clientSecret && (
              <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </div>
        )}

        {/* Opción PayPal */}
        <div className="TarjetasGuardadas">
          <div>PayPal</div>
          <div className="FontSmall">
            {translate('checkout.paypal')}
          </div>
          <label className="ItemTarjeta">
            <input
              type="radio"
              name="payment"
              checked={selectedPayment === "paypal"}
              onChange={() => setSelectedPayment("paypal")}
            />
            <div className="Selected">
              <div>
                <img src={logopaypal} alt="logo paypal" />
              </div>
            </div>
          </label>
        </div>
        {selectedPayment === "paypal" && (
          <PayPaypal />
          // <div className="FormButtonPaypal">
          //   <a href={paypalLink}>
          //   <img src={formLoremPaypal} alt="Formulario PayPal" style={{ cursor:'pointer' }} />
          //   </a>
          // </div>
        )}

       
      </article>
    </section>
  );
};

export default Component;
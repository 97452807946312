import React, { useRef, useEffect, useState, useContext } from "react";

import $ from "jquery"; // Importación de jQuery
import { useNavigate, useParams } from "react-router-dom";
import ProductoAgotadoBox from "./ProductoAgotadoBox";
import PodriaInteresarte from "./PodriaInteresarte";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Fancybox } from "@fancyapps/ui";
import imgLayer from "../../assets/images/layer_gr.png";
import logozelle from "../../assets/icons/zelle.svg";
import logovenmo from "../../assets/icons/vemo.svg";
import MigajasCategorias from "../../components/MigajasCategorias";
import { CartContext } from "../../context/CardContext";
import { TokenContext } from "../../context/TokenContext";
import { useSnackbar } from "notistack";
import axios from "../../fetch/axios"
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import { useTranslation } from "../../hooks/useTranslation";

const DetallesProducto = () => {

  const [product, setProduct] = useState(null);
  const [thumbnails, setThumbnails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const [maxquantity, setMaxquantity] = useState(0);
  const [thumbnailActive, setThumbnailActive] = useState(0);
  const [tallasActive, setTallasActive] = useState(0);
  const [selectedTalla, setSelectedTalla] = useState(null);
  const [letterTalla, setLetterTalla] = useState(null);
  const { uri } = useParams();
  const ThumbnailRef = useRef(null);
  const TallasRef = useRef(null);
  const GranImagen = useRef(null);
  const navigate = useNavigate();
  const ImageActiveIndex = 0;
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { addToCart, addPrevent, removePrevent } = useContext(CartContext);
  const { getToken } = useContext(TokenContext);
  const { lang, currency } = useContext(LanguageCurrencyContext);
  const {translate} = useTranslation();


  useEffect(() => {
    setIsLoading(true);
    axios.get(`/products/detail?uri=${uri}` + `&lang=` + lang + `&currency=` + currency, {
      headers: { 'Authorization': 'Bearer ' + getToken() }
    })
      .then((response) => {
        setProduct(response.data);
        setThumbnails(response.data.gallery.map((img) => img.thumb));
        setSelectedTalla(response.data.talla);
        setTallasActive(CurrentPosTalla(response.data.sizes, response.data.talla));
        //eliminamos el preventivo agregado al carrito
        removePrevent()
      })
      .catch((error) => {
        console.error("Error fetching product data:", error)
        navigate('/404')

      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [uri, lang, currency]);

  useEffect(() => {
    // Código jQuery para gestionar eventos después de renderizar el DOM
    $(".Thumbnail li").on("click", function () {
      const backgroundImage = $(this).css("background-image");
      $(this).siblings().removeClass("Selected");
      $(this).addClass("Selected");
      $(GranImagen.current).css("background-image", backgroundImage);
    });

    Fancybox.bind("[data-fancybox]", {
      // Opciones de Fancybox si
    });

    // Cleanup: elimina listeners para evitar fugas de memoria
    return () => {
      $(".Thumbnail li").off("click");
      Fancybox.unbind("[data-fancybox]"); // Limpieza si es necesario
    };
  }, []);

  if (isLoading || !product) {
    return (
      <div className="loading-container">
        <LoadingCard />
      </div>
    );
  }

  function Thumbnail() {
    return (
      <ul ref={ThumbnailRef}>
        {thumbnails.map((image, index) => (
          <li
            key={index}
            style={{ backgroundImage: `url(${image})` }}
            className={index === ImageActiveIndex ? "Selected" : ""}
          ></li>
        ))}
      </ul>
    );
  }

  function ThumbnailPos(plus) {
    let newThumbnailActive = thumbnailActive;
    if (plus === 1 && newThumbnailActive < thumbnails.length - 1) {
      newThumbnailActive += 1;
    }
    if (plus !== 1 && newThumbnailActive > 0) {
      newThumbnailActive -= 1;
    }
    setThumbnailActive(newThumbnailActive);

    const selectedThumbnail = thumbnails[newThumbnailActive];
    $(GranImagen.current).css("background-image", `url(${selectedThumbnail})`);

    Array.from(ThumbnailRef.current.children).forEach((thumbnail, index) => {
      thumbnail.classList.remove("Selected");
      if (newThumbnailActive === index) {
        thumbnail.classList.add("Selected");
      }
    });
  }

  function CurrentPosTalla(sizes, talla) {
    return sizes.findIndex((obj) => obj.letter === talla);
  }

  function TallasPos(plus) {
    let newTallasActive = tallasActive;
    if (plus === 1 && newTallasActive < product.sizes.length - 4) {
      newTallasActive += 1;
    }
    if (plus !== 1 && newTallasActive > 0) {
      newTallasActive -= 1;
    }
    setTallasActive(newTallasActive);

    let extra = 1.15 * (newTallasActive - 1);
    extra = extra < 0 ? 0 : extra;
    let left = 26 * newTallasActive;
    left -= extra;
    TallasRef.current.firstElementChild.style.marginLeft = `-${left}%`;
  }

  const handleIncrement = () => {

    if (!letterTalla) {
      enqueueSnackbar("Debe de seleccionar una talla", { variant: "error" });
      return
    }
    if (quantity < maxquantity) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrement = () => {
    if (!letterTalla) {
      enqueueSnackbar("Debe de seleccionar una talla", { variant: "error" });
      return
    }
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleTallaClick = (item) => {

    setSelectedTalla(item.id);     //obtiene el id de la talla saeleccionada
    setLetterTalla(item.letter)    //obtiene la letra de la talla seleccionada
    setMaxquantity(item.quantity)  //obtiene la maxima cantidad disponible por esa talla seleccionada

    //colocar el numero de cantida si la talla ya estaen el carrito
    const items = JSON.parse(localStorage.getItem('cart') || '[]')

    if (items.length > 0) {

      const existingItem = items.find(cartItem =>
        cartItem.id === product.id && parseInt(cartItem.size_id) === item.id
      );

      if (existingItem) {
        setQuantity(existingItem.quantity)
      } else {
        setQuantity(0)
      }
    } else {

      setQuantity(0)                 //resetea el contador a 0
    }

  };


  const handleClick = () => {

    if (!getToken()) {
      navigate('/login')
      return;
    }

    if (!letterTalla || !quantity || quantity < 1) {
      enqueueSnackbar("Debe de seleccionar una talla con una cantidad valida", { variant: "error" });
      return
    }

    const item = {
      "id": product.id,
      "size_id": selectedTalla,
      "quantity": quantity,
      "price": product.price,
      "name": product.name,
      "size": letterTalla,
      "description": product.description,
      "uri": product.uri,
      "desktop_image": product.gallery[0].image
    };

    addToCart(item);

    //guardar en el storage temporalmente el producto recien agregado
    addPrevent(item)

    navigate('/agregado-carrito')

  };

  const comprarAhora = () => {

    if (!getToken()) {
      navigate('/login')
      return;
    }

    if (!letterTalla || !quantity || quantity < 1) {
      enqueueSnackbar("Debe de seleccionar una talla con una cantidad valida", { variant: "error" });
      return
    }

    const item = {
      "id": product.id,
      "size_id": selectedTalla,
      "quantity": quantity,
      "price": product.price,
      "name": product.name,
      "size": letterTalla,
      "description": product.description,
      "uri": product.uri,
      "desktop_image": product.gallery[0].image
    };

    try {
      addToCart(item);
      navigate("/checkout-envio")
    } catch (error) {
      enqueueSnackbar("Ocurrio un error, intente mas tarde", { variant: "error" });
    }

  }


  const AddToHeart = async (event, id) => {

    try {

      if (!getToken()) {
        navigate('/login')
      }
      const res = await axios.put(
        "/products/favorite",
        {
          product_id: id,
          status: !product.favorite
        },
        {
          headers:
          {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + getToken()
          }
        }
      );
      product.favorite = !product.favorite;

      event.target.classList.toggle('icon-Hearty');
      event.target.classList.toggle('icon-HeartFill');
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("No se pudo agregar a favoritos", { variant: "error" });
    }
  }

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <MigajasCategorias
        nivel1={product.category ? product.category : 'loading...'}
        nivel2={product ? product.name : ''}
        redirect={product.category_uri}
      />

      {product.sizes.every(size => size.quantity === 0) && <ProductoAgotadoBox product={product} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}

      <section className="DetallesProducto">
        <div>
          {/* ColeccionItems */}
          <div className="ProductoImagenes">
            <div
              ref={GranImagen}
              style={{
                backgroundImage: `url(${thumbnails[ImageActiveIndex]})`,
              }}
            >
              <div className="RotateFlag">
                {product.web_exclusive && (
                  <div className="icon-CursorClick">WEB EXCLUSIVE</div>
                )}
                {product.limited_edition && (
                  <div className="icon-CrownSimple">LIMITED EDITION</div>
                )}
              </div>
              <div>
                <i
                  className={!product.favorite ? "icon-Hearty" : "icon-HeartFill"}
                  style={{ color: !product.favorite ? "" : "red" }}
                  onClick={(event) => { AddToHeart(event, product.id) }}
                ></i>
              </div>
            </div>
            <div className="Thumbnail" data-i={thumbnailActive}>
              <div className="icon-CaretLeft" onClick={() => ThumbnailPos(-1)}></div>
              {Thumbnail()}
              <div
                className="icon-CaretRight"
                onClick={() => ThumbnailPos(1)}
              ></div>
            </div>
          </div>
          <div className="ProductoDescripcion">
            <div>
              <div className="ItemDesc">
                {product.sizes.every(size => size.quantity === 0) && (
                  <div className="NoDisponible">{translate('product.no_available')}</div>
                )}
                <h4>{product.name}</h4>
                <ul>
                  <li>{product.collection}</li>
                </ul>
                <h6>{product.price}</h6>
                <em>{product.description}</em>
              </div>
              {/* <div className="SeleccionaColor" >
                <h6>Seleccionar color:</h6>
                <div>
                  <div className="grid">
                    <button className="color-1"></button>
                    <button className="color-2"></button>
                    <button className="color-3"></button>
                    <button className="color-4"></button>
                  </div>
                </div>
              </div> */}
              <div className="SeleccionaCantidad" >
                <h6>{translate('product.quanty')}:</h6>
                <div>
                  <div className="counter">
                    <button className="counter__btn counter__btn--decrement" onClick={handleDecrement}>-</button>
                    <input type="text" className="counter__input" value={quantity} readOnly />
                    <button
                      className="counter__btn counter__btn--increment"
                      onClick={() => handleIncrement()}
                    // disabled={quantity === maxquantity}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div className="EncuentraTalla">
                <a
                  href="#modal-talla" // Enlace al contenedor modal
                  data-fancybox // Atributo necesario para abrir con Fancybox
                  data-src="#modal-talla" // Referencia al modal
                  data-type="inline" // Especifica que es contenido inline
                >
                  <i className="icon-Shirt"></i>
                  <em>{translate('product.find_size')}</em>
                </a>
              </div>

              {/* Contenedor del modal */}
              <div id="modal-talla" className="modal-talla" style={{ display: "none", maxWidth: "700px" }}>
                <div className="modal-content">
                  <h2><i></i>{translate('product.find_size')}</h2>
                  <div className="text"><strong>{translate('product.important')}: </strong> Las medidas de las tallas pueden variar según el producto. Te invitamos a revisar la siguiente información para que elijas la talla ideal para ti.</div>
                  <img src={imgLayer} alt="Guía de tallas" />
                </div>
              </div>

              <div className="SeleccionaTalla" data-i={tallasActive}>
                <h6>{translate('product.select_size')}:</h6>
                <div className="text"><strong>{translate('product.important')}: </strong>{translate('product.size_information')} </div>
                <div>
                  <div className="icon-CaretLeft" onClick={() => TallasPos(-1)}></div>
                  <ul ref={TallasRef}>
                    {product.sizes.map((item, index) => (
                      <li
                        key={index}
                        className={`${item.id === selectedTalla ? "Selected" : ""
                          } ButtonLightMedium ${item.quantity > 0 ? "" : "Disabled"
                          }`}
                        onClick={() => handleTallaClick(item)}
                      >
                        {item.letter}
                      </li>
                    ))}
                  </ul>
                  <div
                    className="icon-CaretRight"
                    onClick={() => TallasPos(1)}
                  ></div>
                </div>
              </div>

              <div className="other-payment-methods">
                <div className="title">Importante:</div>
                <div className="text">
                  <div className="box">Si quieres pagar con <img src={logozelle} alt="zelle" /> ó <img src={logovenmo} alt="venmo" /> por favor contáctanos al correo <a href="" target="_blank">ejemplo@mail.com</a> ó escríbenos por <a href="" target="_blank">WhatsApp</a>.</div>
                </div>
              </div>


              {product.sizes.some(size => size.quantity > 0) ? (
                <>
                  <div className="ButtonDarkMedium" onClick={() => { handleClick() }}>
                    <i className="icon-Trolley"></i>
                    {translate('product.add_cart')}
                  </div>
                  <div
                    className="ButtonLightMedium"
                    onClick={() => comprarAhora()}
                  >
                    {translate('product.buy')}
                  </div>
                </>
              ) : (
                <div className="ButtonDarkMedium" onClick={handleModalOpen}>
                  <i className="icon-Bell"></i>
                  {translate('product.leet_me')}
                </div>
              )}
              {isModalOpen && <ProductoAgotadoBox isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}
                // onClose={() => setIsModalOpen(false)}
                product={product} />}
              <div className="TeAvisaremos Hide">
                <i className="icon-Check"></i>
                <span>
                  {translate('product.will_send')}
                </span>
              </div>
              <div>
                <h6>{translate('product.detail')}:</h6>
                <p dangerouslySetInnerHTML={{ __html: product.detail }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <PodriaInteresarte
        title="Productos relacionados"
        related={product.related}
      />
    </>
  );
};

const LoadingCard = () => {
  return (
    <>
      <div className="col-md-3">
        <div className="wrapper">
          <div className="card-loader card-loader--tabs"></div>
        </div>
      </div>
    </>
  );
};

export default DetallesProducto;

export const translationsES = {
  welcome: "¡Bienvenido a ArteOnU!",
  my_account_: "Mi Cuenta",
  interesed: 'También puede interesarte',
  view:{
      shop:"VER TIENDA",
      profile:"VER MI PERFIL",
      myprofile:{
        my_profile:"Mi Perfil",
        my_info:"Mis Datos",
        name: 'Nombre',
        phone: 'Telefono',
        email: 'Correo eléctronico',
        membership_number:'No. Afiliación',
        edit: 'Editar datos',
        edit_my_profile: 'Editar mis datos',
        log_out: 'Cerrar Sesión',
        store:'GUARDAR DATOS',
      },
      orders:{
        title:'Pedidos',
        subtitle:'Rastrear paquetes, devolver pedidos o comprar algo de nuevo.',
        view:'VER MIS PEDIDOS',
      },
      address:{
        title:'Mis direcciones',
        subtitle:'Mis direcciones para entrega de tus compras.',
        edit:'Editar',
        add:'Agregar',
        address:'Dirección',
        edit_shipping:'Editar dirección de envío',
        add_shipping:'Agregar dirección de envío',
        street_numer_apt_1:'Línea de dirección 1',
        street_numer_apt_2:'Línea de dirección 2',
        postal_code:'Código Postal',
        city:'Ciudad',
        detail:'Detalles',
        adddetail:'Agrega detalles de la dirección',
        update: 'ACTUALIZAR DATOS',
        store:'GUARDAR DATOS',
        delete:'Eliminar esta dirección de envío'
      },
      loading:'cardango...',
    },
    login: {
      title: 'Iniciar Sesión',
      titleM: 'INICIAR SESION',
      registerM: 'REGISTRARSE',
      already_account:'¿Ya tienes una cuenta?',
      country: 'Pais',
      name: 'Nombre',
      email: 'Email',
      password: 'Contraseña',
      submit: 'INICIAR SESIÓN',
      forgotPassword: '¿Olvidaste tu contraseña?',
      recoverPassword: 'Recuperar contraseña',
      google: 'Continuar con Google',
      facebook: 'Continuar con Facebook',
      wemail: 'Continuar con email',
      no_register:'¿Aun no estas registrado?',
      create:'Crear cuenta',
      password_no:'Contraseña incorrecta',
      forgout: '¿Olvidaste tu contraseña?',
      confirm_email:'Confirma tu correo electrónico',
      register_success:'Registro exitoso',
      phone:'Número movil',
      term1:'Al hacer click en "Registrarse" aceptas nuestros',
      term2:'Términos y Condiciones',
      term3:'Términos de Uso',
      term4:'Términos de Servicio',
      term5:'Políticas de Privacidad',
      term6:'así como el',
      term7:'Aviso de Privacidad',
    },
    recovery:{
      enter:'Ingresa el correo electrónico que utilizaste al registrarte para recuperar tu contraseña. Recibirás un enlace para restablecer tu contraseña',
      title:'Recuperar contraseña',
      titleM:'RECUPERAR CONTRASEÑA',
      change:'Cambiar contraseña',
    },
    shopping:{
      title:'Carrito de compra',
      discount_code:'Código de descuento',
      if_code:'Si cuentas con un código de descuento, ingrésalo aquí.',
      total_products:'Productos totales',
      subtotal:'Subtotal',
      discount:'Descuento',
      shipping:'Gastos de envio',
      purchased:'REALIZAR COMPRA',
      apply:'APLICAR',
    },
    product:{
      detail:'Detalle de producto',
      store:'GUARDAR',
      size:'Talla',
      delete:'Eliminar',
      edit:'Editar',
      quanty:'Cantidad',
      find_size:'Encuentra tu talla',
      select_size:'Selecciona la talla',
      important:'Importante',
      size_information:'Las medidas de las tallas pueden variar según el producto. Consulta la sección “Encuentra tu talla” y elige la que mejor se adapte a ti.',
      no_available:'Producto NO DISPONIBLE',
      add_cart:'AGREGAR AL CARRITO',
      buy:'COMPRAR AHORA',
      leet_me:'AVISARME CUANDO ESTE DISPONIBLE',
      will_send:'Te enviaremos un correo cuando este producto esté disponible.',
      no_stock:'Producto agotado',
      no_stock_layer:'Este producto está agotado. Puedes introducir tu email y te enviaremos un correo cuando el producto esté disponible de nuevo',

    },
    checkout:{
      address:'Dirección de envío',
      stored:'Mis direcciones',
      select:'Selecciona la dirección a donde enviaremos tu compra.',
      with_pay:'El cobro es standard shipping, si requieres otra forma de envío, por favor contáctanos.',
      add_address:'AGREGAR OTRA DIRECCION',
      edit_address:'Editar',
      continue:'CONTINUAR',
      indicated:'Indica la dirección a donde enviaremos tu compra.',
      information:'Información de pago',
      credicard:'Tarjeta de crédito o débito',
      card:'Tarjeta',
      paypal:'Tu pago se realizará desde tu cuenta de PayPal',
      success:'¡Compra exitosa!',
      your_pay:'Tu pago fue realizado con éxito',
      send1:'We have sent an email to: ',
      send2:'with the details of your purchase',
      check:'VER MIS PEDIDOS',
      continue_shopping:'SEGUIR COMPRANDO',
    },
    summary:{
      title:'Tu compra',
      see:'Ver resúmen'

    },
    legal:{
      dudas:"¿Tienes dudas?",
      contact:"CONTACTANOS",
      description:"¡Ponte en contacto con nosotros!",
    }
  };